import "core-js/modules/es.array.push.js";
import { mixins } from "@/plugins/mixins";
import { ElLoading, ElMessage } from "element-plus";
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'

import bus from "@/plugins/bus";
export default {
  name: "feeStandardRuleAuditAdd",
  mixins: [mixins],
  data() {
    return {
      areaProps: {
        value: 'id',
        label: 'name',
        emitPath: false
      },
      areas: [],
      //区域
      edit: false,
      carTypeList: [],
      feeStandardList: [],
      feeRuleList: [],
      formModel: {
        feeStandard: '',
        areaId: '',
        nextDayType: '累计计费',
        weekend: 'true',
        holiday: 'true',
        maxFee: 0,
        outFenceFee: 0,
        nonParkFee: 0,
        beginDay: '',
        feeDesc: '',
        items: [{
          seq: 1,
          customerType: '',
          dayFeeRuleId: '',
          nightFeeRuleId: '',
          defaultItem: true,
          editable: true
        }]
      },
      formRules: {
        nextDayType: [{
          required: true,
          message: '请选择隔日计费类型',
          trigger: 'blur'
        }],
        areaId: [{
          required: true,
          message: '请选择区域',
          trigger: 'blur'
        }],
        weekend: [{
          required: true,
          message: '请选择周末是否计费',
          trigger: 'blur'
        }],
        holiday: [{
          required: true,
          message: '请选择法定假日是否计费',
          trigger: 'blur'
        }],
        maxFee: [{
          required: true,
          message: '请输入封顶金额',
          trigger: 'blur'
        }],
        outFenceFee: [{
          required: true,
          message: '请输入超运营停放调度费用',
          trigger: 'blur'
        }],
        nonParkFee: [{
          required: true,
          message: '请输入站外停放调度费用',
          trigger: 'blur'
        }],
        feeDesc: [{
          required: true,
          message: '请输入费率描述',
          trigger: 'blur'
        }, {
          max: 512,
          message: '长度不能超过512个字符',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    getAreas() {
      this.areas = [];
      this.$api.uac.area.list().then(res => {
        this.areas = res.data;
      });
    },
    defaultItemChange(changeItem, v) {
      if (v) {
        // 其他改为非默认
        this.formModel.items.forEach(item => {
          if (changeItem.seq !== item.seq) {
            item.defaultItem = false;
          }
        });
      } else {
        // 第一个默认
        this.formModel.items[0].defaultItem = true;
      }
    },
    checkDay(now) {
      return now < new Date();
    },
    showFeeRuleCode(ruleId) {
      let desc = ruleId;
      if (this.feeRuleList) {
        this.feeRuleList.forEach(item => {
          if (item.id === ruleId) {
            desc = item.code;
          }
        });
      }
      return desc;
    },
    showCarTypeDesc(carType) {
      let desc = carType;
      if (this.carTypeList) {
        this.carTypeList.forEach(item => {
          if (item.value === carType) {
            desc = item.label;
          }
        });
      }
      return desc;
    },
    saveItem(item) {
      if (!item.customerType) {
        ElMessage.error('请选择用户类型');
        return;
      }
      if (!item.dayFeeRuleId) {
        ElMessage.error('请选择白天收费规则');
        return;
      }
      item.editable = false;
    },
    addItem() {
      this.formModel.items.push({
        seq: this.formModel.items.length + 1,
        carType: '',
        dayFeeRuleId: '',
        nightFeeRuleId: '',
        defaultItem: false,
        editable: true
      });
    },
    deleteItem(delItem) {
      this.formModel.items.forEach(function (item, index, arr) {
        if (item.seq === delItem.seq) {
          arr.splice(index, 1);
        }
      });
      // 重新排序
      this.formModel.items.forEach((item, index) => {
        item.seq = index + 1;
      });
    },
    submitForm() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          const callBack = res => {
            if (res.code === 200) {
              ElMessage({
                message: res.message,
                type: 'success'
              });
              this.$router.back();
              if (this.$route.meta && this.$route.meta.parent) {
                bus.emit(this.$route.meta.parent, true);
              }
            } else {
              ElMessage.error(res.message);
            }
          };
          if (this.edit) {
            this.$api.business.feeStandardRuleAudit.update(this.formModel).then(callBack);
          } else {
            this.$api.business.feeStandardRuleAudit.create(this.formModel).then(callBack);
          }
        }
      });
    }
  },
  beforeMount() {
    this.getAreas();
    this.$api.business.dictionary.dictItems({
      categoryCode: 'customer_type'
    }).then(res => {
      if (res.code === 200) {
        this.carTypeList = res.data;
      }
    });
    this.$api.business.dictionary.dictItems({
      categoryCode: 'fee_standard'
    }).then(res => {
      if (res.code === 200) {
        this.feeStandardList = res.data;
      }
    });
    this.$api.business.feeRule.getList({
      page: 1,
      size: 50
    }).then(res => {
      if (res.code === 200) {
        this.feeRuleList = res.data.records;
      }
    });
    if (this.$route.query.id) {
      this.edit = true;
      const loading = ElLoading.service();
      this.$api.business.feeStandardRuleAudit.detail(this.$route.query.id).then(res => {
        if (res.code === 200) {
          this.formModel = res.data;
          this.formModel.weekend = '' + this.formModel.weekend;
          this.formModel.holiday = '' + this.formModel.holiday;
        } else if (res.code === 121701) {
          this.$router.replace({
            name: '404'
          });
        } else {
          ElMessage.error(res.message);
          this.$router.back();
        }
      }).finally(() => {
        loading.close();
      });
    }
  }
};